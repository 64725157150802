import Link from "next/link";
import Image from "next/image";
import {LendsqrBtn} from "@components/ui/button/Button";
import {Fragment, useContext, useEffect, useRef, useState} from "react";
import {Sidebar} from "./Sidebar";
import {IHeader} from "interfaces/layout";
import {HeaderDropdown} from "./HeaderDropdown";
import {navlist} from "@utils/data";
import {QRCodeModal} from "@components/ui/modal/Modal";
import {DownloadModalContext} from "@utils/contexts";
import {useRouter} from "next/router";

const Header = ({
  isLenders,
  showProducts,
  setShowProducts,
  navigationBackground,
  isHome,
}: IHeader) => {
  const router = useRouter();
  const currentRoute = router.pathname;

  const {showDownloadModal, setShowDownloadModal} =
    useContext(DownloadModalContext);

  const [openNavbar, setOpenNavbar] = useState(false);
  const [active, setActive] = useState(false);
  const [activeNav, setNav] = useState("");
  const [showMobileExtra, setShowMobileExtra] = useState(false);
  const [showColor, setShowColor] = useState(false);
  const headerRef = useRef(null);
  const [positionRef, setPositionRef] = useState(false);
  const [isPathInArray, setIsPathInArray] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRefs = useRef<(HTMLLIElement | null)[]>([]);

  const handleToggle = () => {
    setOpenNavbar((prev) => !prev);
    setActive((prev) => !prev);
  };

  const handleProductClick = (nav: string) => {
    console.log("fairy land");
    setShowColor(true);

    if (
      setShowProducts !== undefined &&
      showProducts !== undefined &&
      nav !== "Pricing"
    ) {
      setShowProducts(true);
    }

    setNav(nav);
    setShowMobileExtra(false);
  };

  const mobileNavClick = (nav: string) => {
    console.log("extra nav: ", nav);
    setShowMobileExtra(true);

    setNav(nav);
  };

  const handleMouseLeave = (event: React.MouseEvent, index: number) => {
    const relatedTarget = event.relatedTarget as Node;
    if (activeNav === "Pricing") {
      setShowColor(false);
    }

    if (
      dropdownRef.current &&
      buttonRefs.current[index] &&
      !dropdownRef.current?.contains(relatedTarget) &&
      !buttonRefs.current[index]?.contains(relatedTarget) &&
      window.innerWidth >= 1024
    ) {
      setShowProducts && setShowProducts(false);
      setShowColor(false);
    }
  };

  useEffect(() => {
    buttonRefs.current = buttonRefs.current.slice(0, navlist.length);
  }, [navlist]);

  useEffect(() => {
    console.log("document");

    window.addEventListener("scroll", (event) => {
      const position = window.scrollY;
      const headerTarget = headerRef.current as any;

      if (
        position > 10 &&
        headerTarget?.className.indexOf("masthead-sticky") === -1
      ) {
        headerTarget.className = " site-header masthead-sticky";
        // if(positionRef){

        // }
        setPositionRef(true);
      } else if (
        position <= 10 &&
        headerTarget?.className?.indexOf(" site-header masthead uni-width") ===
          -1
      ) {
        headerTarget.className = " header-holder bg-white-200 masthead";
        setPositionRef(false);
      }

      // console.log(positionRef.current.isPassed, "=================is passed");
    });
  }, []);

  useEffect(() => {
    const handleDocumentMouseOver = (event: MouseEvent) => {
      const target = event.target as Node;

      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(target) &&
        !buttonRefs.current.some((ref) => ref && ref.contains(target)) &&
        window.innerWidth >= 1024
      ) {
        setShowProducts && setShowProducts(false);
        setShowColor && setShowColor(false);
      }
    };

    document.addEventListener("mouseover", handleDocumentMouseOver);

    return () => {
      document.removeEventListener("mouseover", handleDocumentMouseOver);
    };
  }, [dropdownRef, buttonRefs, setShowProducts]);

  const paths = ["/terms", "/privacy", "/license", "/lenders", "/about"];

  useEffect(() => {
    if (typeof window !== "undefined") {
      const currentPath = window.location.pathname;
      const pathExists = paths.some((path) => currentPath.includes(path));
      setIsPathInArray(pathExists);
    }
  }, []);

  return (
    <Fragment>
      {!isLenders ? (
        <>
          <div
            className="header-background"
            style={{
              backgroundColor: navigationBackground ?? "rgba(249, 255, 255, 1)",
            }}
          ></div>

          <div
            className="header-holder bg-white-200 masthead"
            style={{
              backgroundColor: navigationBackground,
            }}
            ref={headerRef}
            id="masthead"
          >
            {/* {showProducts && <div className="disable-background"></div>} */}

            <div className="header flex-between-center full-width">
              {!isHome && (
                <Link href="/" className="cursor-pointer">
                  <img
                    src={
                      navigationBackground &&
                      navigationBackground !== "white" &&
                      !positionRef
                        ? "/assets/icons/logo-white.svg"
                        : "/assets/icons/LSQ Logo.svg"
                    }
                    alt="lendsqr logo"
                    width={120}
                    height={40}
                  />
                </Link>
              )}

              {isHome && (
                <Link href="/" className="cursor-pointer">
                  <img
                    src={
                      navigationBackground &&
                      navigationBackground !== "white" &&
                      !positionRef
                        ? "/assets/icons/logo-white.svg"
                        : "/assets/icons/LSQ Logo.svg"
                    }
                    alt="lendsqr logo"
                    width={120}
                    height={40}
                  />
                </Link>
              )}

              <ul className="middle-nav">
                {navlist.map((nav, index) => {
                  return (
                    <li
                      key={nav.name}
                      style={{
                        color:
                          navigationBackground &&
                          navigationBackground !== "white" &&
                          !positionRef
                            ? "white"
                            : "",
                      }}
                      className={`six-15 header-nav text-primary rounded-[4px] hover:!text-green-600 ${
                        !navigationBackground
                          ? "hover:bg-green-700"
                          : "hover:bg-gray-1200"
                      }`}
                      ref={(el) => (buttonRefs.current[index] = el)}
                    >
                      <span
                        onMouseOver={() =>
                          nav.extra && handleProductClick(nav.name)
                        }
                        onMouseLeave={(e) => handleMouseLeave(e, index)}
                      >
                        {nav.name === "Pricing" && (
                          <a href="/pricing">{nav.name}</a>
                        )}

                        {nav.extra && nav.name !== "Pricing" && (
                          <>
                            {nav.name}{" "}
                            {nav.extra && (
                              <img
                                src={
                                  navigationBackground &&
                                  navigationBackground !== "white" &&
                                  !positionRef
                                    ? "/assets/icons/dropdown-white.svg"
                                    : "/assets/icons/arrow-down-small.svg"
                                }
                                alt="down icon"
                                className={`${
                                  nav.name === activeNav &&
                                  showProducts &&
                                  "rotated"
                                }`}
                              />
                            )}
                          </>
                        )}
                      </span>
                    </li>
                  );
                })}
              </ul>

              <img
                src="/assets/icons/burger.svg"
                alt="burger-icon"
                className="burger-icon"
                onClick={() => handleProductClick("mobile")}
              />

              <div className="nav-button-holder">
                <a
                  className="btn-large text-primary six-15"
                  style={{
                    color:
                      navigationBackground &&
                      navigationBackground !== "white" &&
                      !positionRef
                        ? "white"
                        : "",
                  }}
                  target="_blank"
                  href="https://app.lendsqr.com/"
                  rel="noreferrer"
                >
                  Log in
                </a>
                <a
                  target="_blank"
                  href="https://app.lendsqr.com/signup"
                  rel="noreferrer"
                  className="btn-large btn-primary"
                >
                  Create free account
                </a>
              </div>
            </div>
          </div>

          {showDownloadModal && (
            <div className="qr-backdrop fixed top-0 left-0 z-10 w-full h-full z-[500] flex flex-center">
              <QRCodeModal setShowDownloadModal={setShowDownloadModal} />
            </div>
          )}

          {showProducts && activeNav !== "Pricing" && (
            <div className="desktop-dropdown" ref={dropdownRef}>
              <HeaderDropdown
                nav={activeNav}
                setNav={setNav}
                setShowProducts={setShowProducts}
                setShowColor={setShowColor}
                showDownloadModal={showDownloadModal}
                setShowDownloadModal={setShowDownloadModal}
              />
            </div>
          )}

          <div className="mob-header-holder">
            <div className="mob-header">
              <div className="mob-inner-width mob-header-inner">
                {!showMobileExtra && (
                  <a href="/">
                    <img
                      // src={
                      //   navigationBackground && navigationBackground !== "white"
                      //     ? "/assets/icons/logo-white.svg"
                      //     : "/assets/icons/LSQ Logo.svg"
                      // }
                      src="/assets/icons/LSQ Logo.svg"
                      alt="lendsqr logo"
                      width={120}
                      height={40}
                    />
                  </a>
                )}

                {showMobileExtra && (
                  <div
                    className="flex-center cursor-pointer"
                    onClick={() => {
                      setShowMobileExtra(false);
                    }}
                  >
                    <img
                      src="/assets/icons/mob-back-icon.svg"
                      alt="back icon"
                      className="mr-2"
                    />
                    <p className="six-16 text-secondary">Back</p>
                  </div>
                )}

                {!showProducts && (
                  <img
                    src={"/assets/icons/burger.svg"}
                    alt="burger-icon"
                    className="burger-icon"
                    onClick={() => handleProductClick("mobile")}
                  />
                )}

                {showProducts && (
                  <img
                    src={"/assets/icons/mob-close-icon.svg"}
                    alt="burger-icon"
                    className="burger-icon"
                    onClick={() => setShowProducts && setShowProducts(false)}
                  />
                )}
              </div>
            </div>

            {showProducts && (
              <div
                className="mob-nav-container"
                style={{
                  position: "relative",
                  height: "calc(100vh - 72px)",
                  overflowY: "scroll",
                }}
              >
                <div className="mob-header-body">
                  <div className="mob-inner-width">
                    {!showMobileExtra &&
                      navlist.map((nav) => {
                        return (
                          <div
                            className="mob-first-nav cursor-pointer"
                            key={nav.name}
                            onClick={() =>
                              nav.extra && mobileNavClick(nav.name)
                            }
                          >
                            {nav.name === "Pricing" && (
                              <a href={nav?.url}>{nav.name}</a>
                            )}

                            {nav.name !== "Pricing" && (
                              <>
                                <p>{nav.name}</p>
                                <img src="/assets/icons/mob-right-icon.svg" />
                              </>
                            )}
                          </div>
                        );
                      })}

                    {showMobileExtra && (
                      <HeaderDropdown nav={activeNav} setNav={setNav} />
                    )}
                  </div>
                </div>
                <div className="nav-mob-button-holder">
                  <a
                    className="btn-large text-primary six-15"
                    style={{
                      color:
                        navigationBackground &&
                        navigationBackground !== "white" &&
                        !positionRef
                          ? "white"
                          : "",
                    }}
                    target="_blank"
                    href="https://app.lendsqr.com/"
                    rel="noreferrer"
                  >
                    Log in
                  </a>
                  <a
                    target="_blank"
                    href="https://app.lendsqr.com/signup"
                    rel="noreferrer"
                    className="btn-large btn-primary"
                  >
                    Create free account
                  </a>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="bg-white-100 sticky top-0 z-50 shadow-xs shadow-headerCustom">
          <nav className="max-w-custom mx-auto lg:px-0 p-4 flex justify-between items-center md:w-[95%]">
            <Link href="/" passHref>
              <a>
                <Image
                  className="cursor-pointer"
                  src="/assets/icons/lenders-logo.svg"
                  width={166}
                  height={40}
                  alt="Lendsqr | start lending for free in minutes"
                />
              </a>
            </Link>

            <div onClick={handleToggle} className="three col lg:hidden">
              <div
                className="hamburger cursor-pointer"
                id={!active ? "hamburger-1.is-active" : "hamburger-1"}
              >
                <span className="line  line-lender  w-[20px] h-[3px] !bg-blue-700 my-1 transition-[0.4s]"></span>
                <span className="line  line-lender  w-[20px] h-[3px] !bg-blue-700 my-1 transition-[0.4s]"></span>
                <span className="line  line-lender  w-[20px] h-[3px] !bg-blue-700 my-1 transition-[0.4s]"></span>
              </div>
            </div>

            {openNavbar && <Sidebar isLenders showProducts={showProducts} />}

            <div className="w-[35%] hidden lg:block xl:w-[30%]">
              <ul className="flex justify-between items-center text-primary gap-3">
                {[
                  ["Find a Loan", "/offers"],
                  ["Find a Lender", "/lenders"],
                  ["Find a Provider", "/providers"],
                ].map(([title, url]) => (
                  <Fragment key={title}>
                    <li
                      className={`transition duration-200 body-md-bold inter cursor-pointer hover:text-green-600 w-max ${
                        currentRoute.includes(url) ? "text-green-600" : ""
                      }`}
                    >
                      <Link key={title} href={url}>
                        {title}
                      </Link>
                    </li>
                  </Fragment>
                ))}
              </ul>
            </div>

            <div className="hidden lg:flex gap-1 lg:w-[31%] xl:w-[24%] justify-end">
              <a
                className="btn-large text-primary six-15"
                style={{
                  color:
                    navigationBackground &&
                    navigationBackground !== "white" &&
                    !positionRef
                      ? "white"
                      : "",
                }}
                target="_blank"
                href="https://lsq.li/log-in?link=https://app.lendsqr.com&source=top-nav"
                rel="noreferrer"
              >
                Log in
              </a>
              <a
                target="_blank"
                href="https://lsq.li/become-a-lender?link=https://app.lendsqr.com/signup&source=navbar"
                rel="noreferrer"
                className="btn-large btn-primary"
              >
                Become a Lender
              </a>
            </div>
          </nav>
        </div>
      )}
    </Fragment>
  );
};

export {Header};
